// src/pages/404.js

import * as React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import PageTemplate from '../templates/page';
import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <h1>Page not found!</h1>
    </Layout>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
    templateMap: {
        page: PageTemplate,
    },
});
